import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import PasswordStrengthIndicator from '~/components/PasswordStrengthIndicator'
import { inputStyles } from '~/utils/styled-classes'

export default function PasswordInput({
  showPasswordStrengthIndicator = false,
  ...props
}: {
  showPasswordStrengthIndicator?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>) {
  const [value, setValue] = useState('')
  const [isVisible, toggleVisibility] = useState(false)

  const visibleIcon = isVisible ? faEye : faEyeSlash
  const ariaLabel = visibleIcon ? 'Show Password' : 'Hide Password'

  return (
    <div>
      <div className="relative">
        <input
          className={inputStyles.textbox}
          {...props}
          type={isVisible ? 'text' : 'password'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        <button
          type="button"
          className="absolute bottom-[8px] right-[10px]"
          aria-label={ariaLabel}
          onClick={() => toggleVisibility(!isVisible)}
        >
          <FontAwesomeIcon fixedWidth icon={visibleIcon} className="text-app-orange-base" />
        </button>
      </div>

      {showPasswordStrengthIndicator && <PasswordStrengthIndicator password={value} />}
    </div>
  )
}
