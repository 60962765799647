import cn from 'classnames'
import { forwardRef } from 'react'

type FieldErrorProps = JSX.IntrinsicElements['p']

function FieldError(props: FieldErrorProps, ref: React.Ref<HTMLParagraphElement>) {
  const { children, className, ...rest } = props

  if (!children) return null

  return (
    <p ref={ref} className={cn(styles.base, className)} {...rest}>
      {children}
    </p>
  )
}

const styles = {
  base: 'text-red-500 text-xs font-medium mt-1 dark:text-red-400',
}

export default forwardRef(FieldError)
