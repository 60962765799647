import { faFaceFrown, faFaceLaugh } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PasswordStrengthIndicator({ password = '' }: { password: string }) {
  const strongClassName = 'text-[#80C731] inline-block font-[400] leading-[14px] text-[14px]'
  const weakClassName = 'text-danger-baase inline-block font-[400] leading-[14px] text-[14px]'
  const containsSpecialChar = password.match(/.*[@$!%*?&]/)
  const containsUpperCaseChar = password.match(/.*[A-Z]/)
  const containsLowerCaseChar = password.match(/.*[a-z]/)
  const containsDigit = password.match(/.*\d/)
  const hasMinLength = password.length >= 7

  if (password === '') {
    return null
  } else if (!hasMinLength) {
    return (
      <div className="mt-1">
        <FontAwesomeIcon icon={faFaceFrown} className="mr-1 ml-1 text-danger-base" />
        <span className={weakClassName}>Weak - must be a min. of 8 characters</span>
      </div>
    )
  } else if (!containsSpecialChar) {
    return (
      <div className="mt-1">
        <FontAwesomeIcon icon={faFaceFrown} className="mr-1 ml-1 text-danger-base" />
        <span className={weakClassName}>Weak - must contain one special char (@$!%*?&)</span>
      </div>
    )
  } else if (!containsUpperCaseChar) {
    return (
      <div className="mt-1">
        <FontAwesomeIcon icon={faFaceFrown} className="mr-1 ml-1 text-danger-base" />
        <span className={weakClassName}>Weak - must contain at least one uppercase letter</span>
      </div>
    )
  } else if (!containsLowerCaseChar) {
    return (
      <div className="mt-1">
        <FontAwesomeIcon icon={faFaceFrown} className="mr-1 ml-1 text-danger-base" />
        <span className={weakClassName}>Weak - must contain at least one lowercase letter</span>
      </div>
    )
  } else if (!containsDigit) {
    return (
      <div className="mt-1">
        <FontAwesomeIcon icon={faFaceFrown} className="mr-1 ml-1 text-danger-base" />
        <span className={weakClassName}>Weak - must contain at least one digit</span>
      </div>
    )
  } else {
    return (
      <div className="mt-1">
        <FontAwesomeIcon icon={faFaceLaugh} color="#80C731" className="mr-1 ml-1" />
        <span className={strongClassName}>Great job - this password is amazing!</span>
      </div>
    )
  }
}

export default PasswordStrengthIndicator
